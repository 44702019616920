<template>
	<div>
		<div class="sideBar">
			<div class="go-back-container">
				<a @click="goBack" href="javascript:void(0)" class="go-back">
					<img src="../assets/icons/left-arrow.svg" />
					<span>Zurück</span>
				</a>
			</div>
			<h1 class="site-name">Ergebnis</h1>
		</div>
		<div class="content">
			<DataTable :survey="survey" :data="rows" />
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';

export default {
	name: 'Ergebnis',
	components: {
		DataTable: () => import('@/components/ergebnis/DataTable.vue')
	},
	data: function () {
		return {
			rows: []
		};
	},
	computed: {
		...mapGetters(['projects']),
		project: function () {
			return this.projects.find((x) => x.id == this.$route.params.projectID);
		},
		survey: function () {
			return this.project.surveys.find(
				(x) => x.id == this.$route.params.surveyID
			);
		}
	},
	methods: {
		...mapActions(['getPagesBySurvey']),
		goBack: function () {
			this.$router.push({
				name: 'Projekte',
				params: {
					projectID: this.project.id
				}
			});
		}
	},
	mounted: async function () {
		if (this.survey != undefined && this.survey.pages.length == 0)
			await this.getPagesBySurvey({
				projectID: this.project.id,
				surveyID: this.survey.id
			});

		if (this.survey != undefined && this.survey.pages.length != 0) {
			var res = await axios.get('main/getresult.php', {
				params: {
					id: this.survey.id
				}
			});
			this.rows = res.data;

			// eslint-disable-next-line no-console
			console.log(res.data);
		}
	}
};
</script>

<style lang="scss" scoped>
.sideBar {
	width: 200px;
	height: 100%;

	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;

	padding: 0 20px 0 20px;

	background-color: $unnamed-color-1a1a1a;
	overflow-y: hidden;

	.go-back-container {
		margin-top: 40px;

		.go-back {
			height: 19px;
			text-decoration: none;

			@include flex-left;

			span {
				@include bold-text-uppercase-15;
				color: $unnamed-color-999999;
			}

			img {
				width: 12px;
				height: 12px;
				margin-right: 9px;
			}
		}
	}

	.site-name {
		margin-top: 8px;
		margin-bottom: 40px;

		@include bold-text-uppercase-35;
		color: $unnamed-color-ffffff;
	}
}

.content {
	display: block;
	position: absolute;
	height: auto;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	margin-left: 200px;
	background-color: green;
	padding: 67px 40px;

	background-color: $unnamed-color-000000;
	overflow-y: hidden;

	.col {
		flex: 1;
	}

	.row {
		display: flex;

		hr {
			margin: 40px 0 40px 0;
		}

		.delete-page {
			height: 48px;
			width: 100%;

			margin-bottom: 40px;

			padding-right: 20px;

			position: relative;
			text-align: center;

			background: #321308 0% 0% no-repeat padding-box;
			border-radius: 4px;
			opacity: 1;
			border: none;

			cursor: pointer;
			color: #fc6027;

			span {
				@include normal-text;
				color: inherit;
				font-size: 22px;
				font-weight: bold;
				text-transform: uppercase;
			}

			img {
				width: 24px;
				height: 24px;

				position: absolute;
				left: 20px;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		.page-id {
			margin-top: 0;
			margin-bottom: 0;

			@include bold-text-uppercase-35;
			color: $unnamed-color-ffffff;
		}

		.page-editor {
			margin-top: 40px;

			.title {
				margin-bottom: 8px;

				@include normal-text-22;
				color: $unnamed-color-999999;

				.subtitle {
					display: inline-block;
					margin-left: 20px;

					@include normal-text-15;
					color: $unnamed-color-999999;
				}
			}

			.page-name {
				margin-bottom: 20px;

				input {
					width: 100%;
					height: 48px;

					padding: 0 16px;

					border: 1px solid $unnamed-color-999999;
					background: #262626 0% 0% no-repeat padding-box;
					border-radius: 4px;

					@include normal-text-22;
					color: $unnamed-color-ffffff;
				}
			}

			.page-description {
				.editor {
					padding: 20px;

					border: 1px solid $unnamed-color-999999;
					background: #262626 0% 0% no-repeat padding-box;
					border-radius: 4px;
				}
			}
		}
	}
}
</style>
